import { Controller } from '@hotwired/stimulus'
import Swal from 'sweetalert2'

interface SpeakerData {
  [speaker: string]: number
}

// Function to create the bar chart
function createSpeakerBarChart(data: SpeakerData): string {
  // Sort results by percentage in descending order
  const sortedResults = Object.entries(data).sort(([, a], [, b]) => b - a)
  const totalPercentage = sortedResults.reduce((sum, [, percentage]) => sum + percentage, 0)

  // Create the bar chart HTML
  const barChart = sortedResults
    .map(
      ([, percentage], index) => `
      <div style="width: ${(percentage / totalPercentage) * 100}%; height: 100%; background-color: ${index === 0 ? '#34d399' : index === 1 ? '#6ee7b7' : '#a7f3d0'};"></div>
    `,
    )
    .join('')

  // Create the speaker list HTML
  const speakerList = sortedResults
    .map(
      ([speaker, percentage]) => `
      <div style="display: flex; justify-content: space-between; margin-top: 8px;">
        <span style="font-weight: 500;">${speaker}</span>
        <span>${percentage}%</span>
      </div>
    `,
    )
    .join('')

  // Combine the bar chart and speaker list
  return `
      <div style="width: 100%; max-width: 400px;">
        <div style="display: flex; height: 32px; margin-bottom: 16px;">
          ${barChart}
        </div>
        <div>
          ${speakerList}
        </div>
      </div>
    `
}

export default class extends Controller {
  static targets = ['list', 'form', 'modal', 'fileName', 'fileInputWrapper', 'searchForm', 'searchFileName', 'searchFileInputWrapper', 'searchInput']
  settingsChanged = { frame_length: false, sensitivity: false, isolate_voice: false }

  declare readonly fileNameTarget: HTMLSpanElement
  declare readonly fileInputWrapperTarget: HTMLDivElement
  declare readonly modalTarget: HTMLDivElement
  declare readonly formTarget: HTMLFormElement
  declare readonly searchFormTarget: HTMLFormElement
  declare readonly searchFileNameTarget: HTMLSpanElement
  declare readonly searchFileInputWrapperTarget: HTMLDivElement
  declare readonly listTarget: HTMLDivElement
  declare readonly searchInputTarget: HTMLInputElement

  handleFileInputChange(event: Event): void {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      if (event.target.files.length > 0) {
        const fileName = event.target.files[0].name
        this.fileNameTarget.textContent = fileName
        this.fileInputWrapperTarget.classList.add('tw-border-emerald-500')
        this.fileInputWrapperTarget.classList.remove('tw-border-gray-300')
      } else {
        this.fileNameTarget.textContent = 'Upload your audio (WAV or MP3)'
        this.fileInputWrapperTarget.classList.remove('tw-border-emerald-500')
        this.fileInputWrapperTarget.classList.add('tw-border-gray-300')
      }
    }
  }

  handleSearchFileInputChange(event: Event): void {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      if (event.target.files.length > 0) {
        const fileName = event.target.files[0].name
        this.searchFileNameTarget.textContent = fileName
        this.searchFileInputWrapperTarget.classList.add('tw-border-emerald-500')
        this.searchFileInputWrapperTarget.classList.remove('tw-border-gray-300')
      } else {
        this.searchFileNameTarget.textContent = 'Upload your audio (WAV or MP3)'
        this.searchFileInputWrapperTarget.classList.remove('tw-border-emerald-500')
        this.searchFileInputWrapperTarget.classList.add('tw-border-gray-300')
      }
    }
  }

  handleFormSubmit(e: Event): void {
    e.preventDefault()

    const formData = new FormData(this.formTarget)
    const file = formData.get('audio') as File
    if (file.size === 0) {
      return
    }

    // Delete the settings if they are not changed
    Object.keys(this.settingsChanged).forEach((key) => {
      if (this.settingsChanged[key as keyof typeof this.settingsChanged] === false) {
        formData.delete(key)
      }
    })

    this.openModal()
    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && typeof data.item.uuid === 'string') {
          console.log(data)
          window.location.reload()
        } else {
          console.error('Error in processing: ', data.errors[0])
          this.closeModal()
          Swal.fire('Error', data.errors[0], 'error')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        this.closeModal()
        Swal.fire('Error', 'Something went wrong. Please try again', 'error')
      })
  }

  handleSearchSubmit(e: Event): void {
    e.preventDefault()

    const formData = new FormData(this.searchFormTarget)
    const file = formData.get('audio') as File
    if (file.size === 0) {
      Swal.fire('Error', 'Please upload an audio file.', 'error')
      return
    }

    this.openModal()
    fetch(this.searchFormTarget.action, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.closeModal()

          // data.results is {uuid: {name: string, distance: number}}
          // we want to create a dictionary where the key is the name and the value is the distance
          const speakerData: SpeakerData = {}
          Object.entries(data.results).forEach(([, result]) => {
            speakerData[(result as any).name] = (result as any).distance
          })

          // Create the chart
          const chartHtml = createSpeakerBarChart(speakerData)
          Swal.fire({
            title: 'Speaker Distribution',
            html: chartHtml,
            customClass: {
              container: 'custom-swal-container',
              popup: 'custom-swal-popup',
            },
          })
        } else {
          console.error('Error in search: ', data.error)
          this.closeModal()
          Swal.fire('Error', 'Failed to perform the search!', 'error')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        this.closeModal()
        Swal.fire('Error', 'Something went wrong. Please try again', 'error')
      })
  }

  pollForMetrics(uuid: string): void {
    const pollInterval = 3000
    const pollFunction = () => {
      fetch(`/api/v2/detect/${uuid}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            this.updateUIWithMetrics(data.item)
            this.closeModal()
          } else {
            setTimeout(pollFunction, pollInterval)
          }
        })
        .catch((error) => {
          console.log(error)
          this.closeModal()
        })
    }
    pollFunction()
  }

  openModal(): void {
    document.body.classList.add('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-hidden')
    this.modalTarget.classList.add('tw-flex')
  }

  closeModal(): void {
    document.body.classList.remove('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-flex')
    this.modalTarget.classList.add('tw-hidden')
  }

  togglePlayStop(event: Event): void {
    const clickTarget = event.target as HTMLElement
    const button = clickTarget?.closest('button') as HTMLButtonElement // click could be on the icon or the button

    const index = button.dataset.index
    const audio = document.getElementById(`audio_${index}`) as HTMLAudioElement
    const playIcon = button.querySelector('i')

    if (audio.paused) {
      audio.play()
      playIcon?.classList.remove('fa-play-circle')
      playIcon?.classList.add('fa-stop-circle')
      button.classList.add('tw-bg-green-600')
      button.classList.remove('tw-bg-green-500')
    } else {
      audio.pause()
      audio.currentTime = 0
      playIcon?.classList.add('fa-play-circle')
      playIcon?.classList.remove('fa-stop-circle')
      button.classList.remove('tw-bg-green-600')
      button.classList.add('tw-bg-green-500')
    }
  }

  updateUIWithMetrics(detectData: any): void {
    console.log(detectData)
    window.location.reload()
  }

  search(): void {
    clearTimeout((this as any).timeout)
    ;(this as any).timeout = setTimeout(() => {
      const url = new URL(window.location.href)
      url.searchParams.set('search', this.searchInputTarget.value)
      fetch(url.toString(), {
        headers: {
          Accept: 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
        .then((response) => response.text())
        .then((html) => {
          this.listTarget.innerHTML = html
        })
    }, 300) // Debounce for 300ms
  }

  clearSearch(): void {
    this.searchInputTarget.value = ''
    this.search()
  }
}
