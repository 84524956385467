/* eslint-disable react-hooks/rules-of-hooks */ // false postitive by react hooks eslint plugin, useDebounce is not a react hook
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['progress']

  declare readonly hasProgressTarget: boolean
  declare readonly progressTarget: HTMLElement
  declare pollingInterval: number

  connect() {
    this.pollingInterval = 5000
  }
}
