/* eslint-disable react-hooks/rules-of-hooks */ // false postitive by react hooks eslint plugin, useDebounce is not a react hook
import { ApplicationController, useDebounce, useTargetMutation } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['sample']
  static debounces = ['updateLiveDebounced']

  lastFocusedTextInput: Element | null = null
  lastCaretPosition: number | null = null

  connect() {
    useDebounce(this, { wait: 500 })
    useTargetMutation(this)
  }

  sampleTargetAdded(element: any) {
    if (this.lastFocusedTextInput !== null) {
      const newTextInput = element.querySelector('#' + this.lastFocusedTextInput.id + '')
      // @ts-ignore
      newTextInput.focus()
      // @ts-ignore
      newTextInput.selectionEnd = this.lastCaretPosition
    }
  }

  updateLive(event: any) {
    const form = event.target.closest('form')
    // if this is a text input then lets preserve the focus and caret position
    if (event.target.tagName === 'TEXTAREA' || (event.target.tagName == 'input' && event.target.getAttribute('type') === 'text')) {
      this.lastFocusedTextInput = event.target
      // @ts-ignore
      this.lastCaretPosition = event.target.selectionStart
    }

    if (form !== null) {
      form.requestSubmit()
    }
  }

  updateLiveDebounced(event: any) {
    this.updateLive(event)
  }

  changeIcon(event: any) {
    const openIcon = event.currentTarget.querySelector('#openIcon')
    const closeIcon = event.currentTarget.querySelector('#closeIcon')
    if (openIcon !== null && closeIcon !== null) {
      if (openIcon.style.display === 'none') {
        openIcon.style.display = 'inline'
        closeIcon.style.display = 'none'
      } else {
        openIcon.style.display = 'none'
        closeIcon.style.display = 'inline'
      }
    }
  }
}
