import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newSample']

  showNewSample() {
    // @ts-ignore
    this.newSampleTarget.style.display = 'block'
  }

  hideNewSample() {
    // @ts-ignore
    this.newSampleTarget.style.display = 'none'
  }
}
