/* eslint-disable react-hooks/rules-of-hooks */ // false postitive by react hooks eslint plugin, useDebounce is not a react hook
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['tts', 'sts', 'fill']

  declare readonly hasTtsTarget: boolean
  declare readonly ttsTarget: HTMLElement
  declare readonly hasFillTarget: boolean
  declare readonly fillTarget: HTMLElement
  declare readonly hasStsTarget: boolean
  declare readonly stsTarget: HTMLElement

  declare pollingInterval: number

  connect() {
    this.pollingInterval = 5000
  }
}
