window.play = function (uuid) {
  var audioElement = document.getElementById(`audio-${uuid}`)
  var playButton = document.getElementById(`play-${uuid}`)
  var icon = playButton.querySelector('i')

  if (icon.classList.contains('fa-play')) {
    icon.classList.remove('fa-play')
    icon.classList.add('fa-pause')
  }

  if (!audioElement) {
    fetch(`/api/v1/projects/${PROJECT_ID}/clips/${uuid}`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const sound = new Audio(data.link)
        sound.id = `audio-${uuid}`
        sound.play()
        sound.onended = onPlayEnd.bind(this, uuid)
        sound.style.setProperty('display', 'none')
        document.body.appendChild(sound)
      })
  } else {
    if (audioElement.paused) {
      audioElement.play()
      audioElement.onended = onPlayEnd.bind(this, uuid)
    } else {
      audioElement.pause()
      onPlayEnd(uuid)
    }
  }
}

window.onPlayEnd = function (uuid) {
  var playButton = document.getElementById(`play-${uuid}`)
  var icon = playButton.querySelector('i')
  icon.classList.remove('fa-pause')
  icon.classList.add('fa-play')
}

window.validate = function () {
  if ($('#PodFile').val() !== '' && $('#PodFile').val() !== undefined) {
    document.getElementById('PodCreateForm').submit()
    return
  }

  var pod_title = document.getElementById('PodTitle')
  if (pod_title.value.length <= 0) {
    swal.fire('Please give your Clip a Title.')
    return
  }

  if (window.chars <= LIMIT && window.chars > 0) {
    document.getElementById('PodCreateForm').submit()
  } else if (window.chars === 0) {
    swal.fire("We can't generate text that is blank. Let's give the computer something to say.")
  } else {
    swal.fire("Please make sure that the content you've written is within your allowed character limit.")
  }
}
